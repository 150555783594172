import React from "react"
import { Link } from "gatsby"

import { clickEvent } from "../lib/analytics"
import logo from "../images/logo.svg"

export const RNSPromo = () => {
  return (
    <div className="bg-brand-blue rounded-md py-6 px-4 text-white relative flex flex-col md:flex-row items-center">
      <img
        className="h-60 mr-2"
        src={logo}
        alt="React Native School Logo"
        title="React Native School Logo"
      />
      <div>
        <p className="font-bold text-3xl mb-2">React Native School</p>
        <p className="font-normal text-lg mb-4">
          Want to further level up as a React Native developer? Join React
          Native School! You'll get access to all of our courses and our private
          Slack community.
        </p>
        <Link
          className=""
          to="/"
          onClick={() =>
            clickEvent({
              event_category: "CTA",
              event_label: "Tutorial Promo",
            })
          }
        >
          <span className="inline-block bg-teal-400 font-bold rounded-md px-4 py-3">
            Learn More
          </span>
        </Link>
      </div>
    </div>
  )
}

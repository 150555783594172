import React from "react"
import { graphql } from "gatsby"
import format from "date-fns/format"
import normalizeUrl from "normalize-url"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { DefaultLayout } from "../layouts/Default"
import { SEO } from "../components/SEO"
import { Tag } from "../components/Tag"
import { RNSPromo } from "../components/Promos"

const Author = ({ name, bio, avatar }) => {
  const image = getImage(avatar)

  return (
    <div className="flex flex-col md:flex-row md:items-center p-4 my-8 bg-blue-gray-50 border-l-8 border-blue-gray-200 rounded-md shadow-sm">
      <GatsbyImage
        image={image}
        alt={name}
        className="rounded-full w-40 h-40 mr-5 mb-2 shadow-md"
      />
      <div>
        <p className="text-gray-700 font-bold text-lg">{name}</p>
        <p className="text-gray-700">{bio}</p>
      </div>
    </div>
  )
}

const BlogPost = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark

  return (
    <DefaultLayout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        canonical_url={frontmatter.canonical_url}
        meta={[
          {
            property: `og:type`,
            content: `article`,
          },
        ]}
      />

      <div className="pt-6 pb-10 border-b border-gray-300">
        {/* TODO: Use tailwinds full stack of supported html */}
        <article className="container mx-auto px-8 md:px-0 max-w-screen-md">
          <p className="block text-gray-500 uppercase font-semibold text-xs tracking-wide mt-8">
            Updated {format(new Date(frontmatter.date), "MMMM d, yyyy")}
          </p>
          <h1 className="text-5xl font-bold leading-tight mt-1 mb-2 text-gray-800">
            {frontmatter.title}
          </h1>
          {frontmatter.canonical_url && (
            <p className="block text-gray-500 font-normal text-md mb-1">
              Originally publish on{" "}
              <a
                href={frontmatter.canonical_url}
                target="_blank"
                rel="noreferrer"
              >
                {
                  normalizeUrl(frontmatter.canonical_url, {
                    stripProtocol: true,
                    stripWWW: true,
                    removeTrailingSlash: true,
                  }).split("/")[0]
                }
              </a>
              .
            </p>
          )}
          {frontmatter.author && <Author {...frontmatter.author} />}

          <div className="mt-6 ">
            {frontmatter.topics
              .filter(t => !!t)
              .map(topic => (
                <Tag key={topic.slug} slug={topic.slug} name={topic.name} />
              ))}
          </div>

          <div className="prose prose-blue md:prose-lg">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </article>
        <div className="max-w-screen-lg mx-8 md:mx-auto md:px-20 mb-8 my-12">
          <RNSPromo />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default BlogPost

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        description
        canonical_url
        topics {
          slug
          name
        }
        author {
          name
          bio
          avatar {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
        }
      }
      html
    }
  }
`

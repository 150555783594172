import React from "react"
import { Link } from "gatsby"

export const Tag = ({ slug, name }) => (
  <Link
    to={`/topic/${slug}`}
    className="px-3 py-2 mr-4 text-xs bg-blue-50 rounded-full text-blue-700 font-semibold hover:underline"
  >
    {name}
  </Link>
)
